import {t} from '@lingui/macro';
import z from 'zod';

const getACHPaymentMethodZodSchema = () =>
  z.object({
    ach: z.object({}),
  });

export const checkoutSupportedCountries = ['US', 'CA', 'AU', 'GB', 'NZ'] as const;

const holderNameRegex = /^[\p{L}A-Za-z]+([ '-][\p{L}A-Za-z]+)*$/u;
const addressRegex = /^[\p{L}a-zA-Z0-9\s,.'-]*$/u;
const getSchemePaymentMethodZodSchema = () =>
  z.object({
    scheme: z.object({
      holderName: z
        .string()
        .trim()
        .min(1, t`Holder Name is required`)
        .regex(holderNameRegex, t`Invalid Holder Name`),
      fullBillingAddress: z
        .object({
          searchAddress: z
            // biome-ignore lint/style/useNamingConvention: <explanation>
            .string({required_error: t`Address is required`})
            .optional(),
          country: z
            // biome-ignore lint/style/useNamingConvention: <explanation>
            .string({required_error: t`Country is required`})
            .trim()
            .min(1, t`Country is required`),
          city: z.string().trim().regex(addressRegex, t`Invalid City`).min(1, t`City is required`),
          street: z
            // biome-ignore lint/style/useNamingConvention: <explanation>
            .string({required_error: t`Address is required`})
            .trim()
            .regex(addressRegex, t`Invalid Address`)
            .min(1, t`Address is required`),
          houseNumberOrName: z.string().trim().regex(addressRegex, t`Invalid Address`).optional(),
          postalCode: z
            .string()
            .trim()
            .regex(addressRegex, t`Invalid Postal Code`)
            .min(1, t`Postal Code is required`),
          stateOrProvince: z
            .string()
            .trim()
            .regex(addressRegex, t`Invalid State or Province`)
            .min(1, t`State or Province is required`),
        })
        .optional()
        .nullable(),
      partialBillingAddress: z
        .object({
          postalCode: z
            .string()
            .trim()
            .regex(addressRegex, t`Invalid Postal Code`)
            .min(1, t`Postal Code is required`)
            .optional(),
        })
        .optional()
        .nullable(),
    }),
  });

type PaymentMethodZodType = ReturnType<typeof getSchemePaymentMethodZodSchema> &
  ReturnType<typeof getACHPaymentMethodZodSchema> &
  z.ZodObject<{type: z.ZodString}>;

export const checkoutV2FormSchema = () =>
  z.object({
    email: z.string().min(1, t`Email is required`).email(t`Email is not valid`),
    paymentMethod: z.discriminatedUnion('type', [
      z
        .object({
          type: z.literal('scheme'),
        })
        .merge(getSchemePaymentMethodZodSchema()),
      z
        .object({
          type: z.literal('ach'),
        })
        .merge(getACHPaymentMethodZodSchema()),
    ]) as unknown as PaymentMethodZodType,
    // discriminatedUnion creates ts typings as {"smth": ''} | {"smthElse": ''} which is not handy and breaks react-hook-form.
    // By this forced transformation we changed the types to {"smth": ''} & {"smthElse": ''}.
    // It resolves the issue and closer to object runtime state.
    // it's only a type fix
  });
export type CheckoutV2FormData = z.infer<ReturnType<typeof checkoutV2FormSchema>>;
